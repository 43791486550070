.ticket {
  background: #333;
  height: 54px;
  position: relative;
  width: 150px;
}

.ticket::before {
  background: #fff;
  border-radius: 50%;
  box-shadow: 150px 0 0 0 #fff, 0 54px 0 0 #fff, 150px 54px 0 0 #fff,
    0 12px 0 -6px #fff, 0 18px 0 -6px #fff, 0 24px 0 -6px #fff,
    0 30px 0 -6px #fff, 0 36px 0 -6px #fff, 0 42px 0 -6px #fff,
    150px 12px 0 -6px #fff, 150px 18px 0 -6px #fff, 150px 24px 0 -6px #fff,
    150px 30px 0 -6px #fff, 150px 36px 0 -6px #fff, 150px 42px 0 -6px #fff;
  content: "";
  display: block;
  height: 15px;
  left: -7.5px;
  position: absolute;
  top: -7.5px;
  width: 15px;
}

.number {
  border-radius: 6px;
  border: 2px solid #aaa;
  color: #fff;
  display: inline-block;
  font-size: 27px;
  left: 50%;
  line-height: 1.5;
  padding: 0 12px;
  position: absolute;
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.number::before,
.number::after {
  background: #333;
  color: #aaa;
  display: inline-block;
  font-size: 8px;
  left: 50%;
  padding: 0 3px;
  position: absolute;
}

.number::before {
  content: "Rey de los";
  transform: translateX(-50%) translateY(-50%);
  top: 0;
}
.number::after {
  bottom: 0;
  content: "Sueños";
  transform: translateX(-50%) translateY(50%);
}
